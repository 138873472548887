<script setup>
import { Head, Link } from '@inertiajs/vue3';
import ShinyButton from '@/Components/ShinyButton.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import Testimonials from '@/Components/Testimonials.vue';
import Footer from '@/Components/Footer.vue';
import MainNav from '@/Components/MainNav.vue';
import Comparison from '@/Components/Comparison.vue';
import CookieConsent from '@/Components/CookieConsent.vue';


//import { ref, onMounted } from 'vue';


defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
  //  styles: Array,
    packages: Array,
    clothing_man: Array,
    clothing_woman: Array,
});

/* function handleImageError() {
    document.getElementById('screenshot-container')?.classList.add('!hidden');
    document.getElementById('docs-card')?.classList.add('!row-span-1');
    document.getElementById('docs-card-content')?.classList.add('!flex-row');
    document.getElementById('background')?.classList.add('!hidden');
} */
import { ref } from 'vue';


const scrollContainer = ref(null);
const styleType = ref(null);
const showFullRow = ref(false);


const scrollToAnchor = (anchorId) => {
    const element = document.getElementById(anchorId);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth'
        });
    }
};

let autoScrollInterval = null;
let imagepadding = 16;

const scrollLeft = (userInitiated = false) => {
    if (userInitiated) {
        clearInterval(autoScrollInterval);
    }
    const figureWidth = scrollContainer.value.querySelector('figure').offsetWidth;
    scrollContainer.value.scrollBy({
        left: -(figureWidth + imagepadding),
        behavior: 'smooth'
    });
    scrollContainer.value.style.scrollBehavior = 'smooth';
    scrollContainer.value.style.transitionTimingFunction = 'ease-in-out';
};

const scrollRight = (userInitiated = false) => {
    if (userInitiated) {
        clearInterval(autoScrollInterval);
    }
    const figureWidth = scrollContainer.value.querySelector('figure').offsetWidth;
    if (scrollContainer.value.scrollLeft + scrollContainer.value.clientWidth >= scrollContainer.value.scrollWidth) {
        scrollContainer.value.scrollTo({
            left: 0,
            behavior: 'smooth'
        });
    } else {
        scrollContainer.value.scrollBy({
            left: figureWidth + imagepadding,
            behavior: 'smooth'
        });
    }
    scrollContainer.value.style.scrollBehavior = 'smooth';
    scrollContainer.value.style.transitionTimingFunction = 'ease-in-out';
};

autoScrollInterval = setInterval(() => scrollRight(), 3000);

/* FAQ Section */

const faqs = ref([
    { question: 'What is HeadshotOnline and how does it work?', answer: 'HeadshotOnline uses advanced AI technology to create professional headshots from your uploaded photos. You provide a set of diverse images, and the AI trains a custom model to generate high-quality headshots with various styles, clothes and backgrounds.' },
    { question: 'How can I make my headshots look their best?', answer: 'Provide high-quality, well-lit photos with a variety of poses and expressions. You can use selfies, but try to take them against different backgrounds and at least a few set of clothes. Make sure the lens doesn\'t distort your proportions. This helps the AI generate more natural and polished headshots.' },
    { question: 'What kind of photos should I upload for the best results?', answer: 'Upload 10-15 DIVERSE well-lit photos featuring different angles, expressions, outfits, and lighting conditions. Avoid heavy makeup and accessories for a more natural look. The AI learns the common features across photos, so try to avoid using photos with the same clothes.' },
    { question: 'How long does it take to generate my headshots?', answer: 'The process usually takes 2-3 hours. You’ll receive an email notification once your headshots are ready for download.' },
    { question: 'Are the AI-generated headshots suitable for professional use?', answer: 'Yes, the headshots are designed to meet professional standards and are perfect for LinkedIn, resumes, and other professional platforms.' },
    { question: 'What if the AI generates some odd-looking photos?', answer: 'Our AI generates a large set of headshots, some of which will look odd or unusual. However, you should receive at least several high-quality headshots. If not, we offer a money-back guarantee!' },
    { question: 'What if I’m not satisfied with the headshots?', answer: 'If you don’t find any decent headshots and haven’t downloaded any, we offer a full refund! Contact support within 3 days of receiving your headshots.' },
    { question: 'Who owns the generated headshots?', answer: 'You own the full rights to your generated headshots, including commercial use, as outlined in our terms of service.' },
    { question: 'Can I use HeadshotOnline for free?', answer: 'HeadshotOnline is a paid service to cover the computational costs.' },
    { question: 'Is my data safe with HeadshotOnline?', answer: 'Yes, we prioritize your privacy. Your uploaded photos are used solely for generating headshots and are deleted within 30 days. You can also delete them manually anytime.' },

    { question: 'Can I use my AI headshots on social media?', answer: 'Absolutely! Our headshots are optimized for professional and social platforms like LinkedIn, Twitter, and Facebook and dating apps.' },

]);

const activeFaq = ref(null);

const toggleFaq = (index) => {
    activeFaq.value = activeFaq.value === index ? null : index;
};



//full screen image
// State variable to track the full-screen image
const fullscreenImage = ref(null);

// Method to show the full-screen image
const showFullscreen = (image) => {
    fullscreenImage.value = image;
    document.body.style.overflow = 'hidden'; // Prevent scrolling when image is fullscreen
};

// Method to close the full-screen image
const closeFullscreen = () => {
    fullscreenImage.value = null;
    document.body.style.overflow = ''; // Restore scrolling when image is closed
};




</script>


<style>
.gradient-text {
    background: linear-gradient(90deg, #0e4aba, #6e91c2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.hero-bg {
    background-color: #042a76;
    position: relative;
    overflow: hidden;
}

.hero-bg::before {
    content: '';
    position: absolute;
    top: -200px;
    left: -200px;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10%),
        radial-gradient(circle, rgba(255, 255, 255, 0.15) 15%, transparent 15%),
        radial-gradient(circle, rgba(255, 255, 255, 0.2) 20%, transparent 20%),
        radial-gradient(circle, rgba(255, 255, 255, 0.25) 25%, transparent 25%),
        radial-gradient(circle, rgba(255, 255, 255, 0.3) 30%, transparent 30%);
    background-size: 150px 150px, 200px 200px, 250px 250px, 300px 300px, 350px 350px;
    background-position: 0 0, 50px 50px, 100px 100px, 150px 150px, 200px 200px;
    opacity: 0.3;
    z-index: 1;
    filter: blur(4px);
    background-blend-mode: screen;
    animation: moveBackground 60s infinite linear;
}

@keyframes moveBackground {
    0% {
        transform: translate(0, 0);
        easing: ease-in-out;
    }

    25% {
        transform: translate(100px, 100px);
        easing: ease-in;
    }

    50% {
        transform: translate(200px, 0px);
        easing: ease-out;
    }

    75% {
        transform: translate(100px, 100px);
        easing: ease-in;
    }

    100% {
        transform: translate(0, 0);
        easing: ease-out;
    }
}

.hero-bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.9));
    z-index: 1;
}

.hero-bg>* {
    position: relative;
    z-index: 2;
}





.gallery-item {
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 10px 20px rgba(25, 25, 25, 0.2);
    /*    border: 1px solid rgba(240, 240, 250, 0.1); */
}


.gallery-item:hover {
    /* transform: rotateY(4deg) rotateX(4deg) scale(1.0); */
}

.gallery-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 1, 30, 0.0), rgba(1, 1, 30, 0.3));
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item::before {
    opacity: 1;
}

.main-heading {
    color: #f0f4ff;
    /* Closer to white with a tinge of dark navy */
}
</style>



<template>

    <Head title="#1 most realistic business headshot maker from selfies, in 2 hours, from home!" />
    <!-- Sticky Navigation -->
    <MainNav :can-login="canLogin" :can-register="canRegister" />


    <CookieConsent />

    <!-- Hero Section -->
    <section class="pt-24 pb-12 hero-bg text-white relative shadow-lg">
        <div class="absolute inset-0 "></div>
        <div class="container mx-auto px-6 flex flex-col md:flex-row items-center relative">

            <div class="w-full md:w-1/2 pr-4 py-20 lg:p-18 al relative">
                <!--                 <h1 class="pb-8 text-left text-             <img src="img/laurels.svg" alt="Laurels" class="w-12 h-12 mr-2">
                <span class="text-white text-lg font-semibold">Highest quality model</span>1> -->
                <div class="relative flex items-center">
                    <img src="/img/laurels.svg" alt="Laurels"
                        class="absolute w-30 h-16 object-cover opacity-70 filter invert">
                    <div class="relative ml-7 text-center leading-tight text-xs">
                        <span class="text-gray-200 text-xs font-semibold">#1 <br>in realistic<br>images</span>
                    </div>
                </div>
                
                <h1 class="text-5xl text-gray-100 font-extrabold sm:text-5xl lg:text-[5.2rem] opacity-95 mt-4 relative">
                    Your <span class="gradient-text"> best headshot</span>,<br> from selfies
                    <div class="absolute top-[-2rem] md:top-[-1rem] right-[3rem] md:right-[5rem] lg:right-[6rem] xl:right-[14rem] transform translate-x-1/2 -translate-y-1/2 rotate-12 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white text-sm font-bold px-2 py-1 shadow-lg text-center">
                        100% risk-free,<br>money-back guarantee
                    </div>
                </h1>
                <p class="text-gray-400 max-w-xl sm:mx-auto xl:mx-0 mt-8 text-lg sm:text-xl leading-relaxed pr-10">
                    <span
                        class="text-gray-400 max-w-xl sm:mx-auto xl:mx-0 mt-8 text-lg sm:text-xl leading-relaxed pr-10">
                        Level up your professional game with stunning headshots that make you stand out, by simply
                        uploading a few selfies. </span>
                </p>
                <p class="text-gray-400 max-w-xl sm:mx-auto xl:mx-0 mt-2 text-lg sm:text-xl leading-relaxed pr-10">
                    <span class="mt-14">Perfect for LinkedIn, X, Teams, your company website or social
                        media.</span>
                </p>
                <div class="items-center gap-x-3 font-medium text-sm sm:flex xl:justify-start mt-6 py-4">
                    <!-- <a class="py-3 px-6 text-center rounded-lg duration-150 block text-gray-100 bg-gradient-to-r from-gray-700 to-gray-800 hover:from-gray-800 hover:to-gray-900 shadow-lg w-full sm:w-auto" href="/#more">Learn more</a> -->

                    <ShinyButton v-if="canRegister" :href="route('login')">
                        Get your headshots
                    </ShinyButton>
                    <button @click="scrollToAnchor('learnmore')"
                        class="p-3 ml-4 border border-blue-800 text-blue-50 rounded-md hover:bg-blue-500 transition duration-50">
                        How it works
                    </button>

                </div>
                
            </div>
          

            <!-- masonry grid -->
            <div class="w-full md:w-1/2 mt-6 md:mt-0 py-16">
                <div class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div v-for="i in 6" :key="i"
                        class="rounded-2xl overflow-hidden shadow-lg gallery-item relative bg-opacity-50 backdrop-filter backdrop-blur-lg">
                        <img :src="`img/gallery/${i + 10}.jpg`" :alt="`Headshot ${i}`"
                            class="w-full h-[300px] object-cover">
                        <div
                            class="absolute inset-0 bg-gradient-to-t from-blue-900 via-blue-800 to-transparent opacity-10">
                        </div>
                        <div class="absolute inset-0 opacity-0 hover:opacity-50 transition-opacity duration-500"></div>
                    </div>
                </div>
            </div>


            <!-- end masonry grid -->


        </div>
    </section>
    <!-- Main Content -->

    <!-- sections -->

<!-- 
    <section class="py-16 bg-gray-50 text-center">
        <div class="container mx-auto px-6 py-8">
            <h1 class="text-5xl font-semibold mb-4">
                <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text py-6">So
                    real</span>, you won't believe it's not a physical photo shoot.
            </h1>
            <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em]">
                We make the highest-quality headshots and our customers are absolutely loving them.
            </h3>
                <Testimonials></Testimonials>
        </div>
    </section> -->


<!-- How It Works Section -->
<section class="py-20 pb-18 bg-white text-center" id="learnmore">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4">
                    Turn <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">selfies</span>
                    into stunning business headshots
                </h1>
                <div class="flex flex-col md:flex-row items-center">
                    <div class="px-4 py-6 mx-auto sm:max-w-xl lg:max-w-screen-xl lg:px-8 lg:py-10 ">
                        <div class="grid gap-8 row-gap-0 lg:grid-cols-1">
                            <div class="relative text-center">
                                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-500 sm:w-20 sm:h-20 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                    </svg>
                                </div>
                                <p class="max-w-md mb-3 sm:mx-auto text-xl font-semibold text-gray-700 tracking-[.011em]">
                                    Send a few pictures of your face.
                                </p>
                                <p class="max-w-md mb-3 sm:mx-auto text-md text-gray-500">
                                    We need a few casual photos to build a 3D virtual model of your appearance. No need to dress up, your face is enough.
                                </p>
                            </div>
                            <div class="flex justify-center items-center m-6">
                                <svg class="w-8 text-gray-700 transform rotate-90" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                    <line fill="none" stroke-miterlimit="10" x1="2" y1="12" x2="22" y2="12"></line>
                                    <polyline fill="none" stroke-miterlimit="10" points="15,5 22,12 15,19 "></polyline>
                                </svg>
                             </div>
                            <div class="relative text-center">
                                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-500 sm:w-20 sm:h-20 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                                    </svg>
                                </div>
                                <p class="max-w-md mb-3 sm:mx-auto text-xl font-semibold text-gray-700 tracking-[.011em]">
                                    Our AI creates a digtal 3D model
                                </p>
                                <p class="max-w-md mb-3 sm:mx-auto text-md text-gray-500">
                                    The AI photographer learns what you look like, while you relax.
                                </p>
                            </div>
                            <div class="flex justify-center items-center m-6">
                                <svg class="w-8 text-gray-700 transform rotate-90" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                    <line fill="none" stroke-miterlimit="10" x1="2" y1="12" x2="22" y2="12"></line>
                                    <polyline fill="none" stroke-miterlimit="10" points="15,5 22,12 15,19 "></polyline>
                                </svg>
                             </div>
                            <div class="relative text-center">
                                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-500 sm:w-20 sm:h-20 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                </div>
                                <p class="max-w-md mb-3 sm:mx-auto text-xl font-semibold text-gray-700 tracking-[.011em]">
                                    Get professional headshots in ~3 hours
                                </p>
                                <p class="max-w-md mb-3 sm:mx-auto text-md text-gray-500">
                                    Sharpest outfits, coolest backgrounds, perfect poses are just a few clicks away.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div id="column-right" class="w-full md:w-1/2 mt-6 md:mt-0 py-16 relative">
                        <div class="rounded-2xl overflow-hidden relative shadow-sm">
                            <img src="/img/s2h-vertical.webp" alt="Headshot" class="object-cover">
                        </div>
                    </div>
                </div>
            </div>
        </section>






        <!-- Social Proof Section -->
        <section class="py-16 bg-gray-50 text-center">
        <div class="container mx-auto px-6">
            <h1 class="text-5xl font-semibold mb-4">
                Join the pros. <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Like a boss</span>
            </h1>
            <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em]">From resumes to portfolios, professionals choose us for headshots that elevate their brand.</h3>

            <!-- component -->
            <Testimonials></Testimonials>

<!--             <div class="mt-6">
                <svg width="100%" height="auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 400" class="mx-auto rounded-lg shadow-lg">
                    <rect width="640" height="400" fill="lightgray" />
                </svg>
            </div> -->
        </div>
        </section> 




    <div id="more">

        <!-- Scrolling Cards Section -->
        <section class="py-16 bg-white text-center">
            <div class="container mx-auto px-6 py-8">
                <h1 class="text-5xl font-semibold mb-4">
                    <span
                        class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text py-8">Picture-perfect
                        you.</span> Satisfaction guaranteed or money back.
                </h1>
                <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em]">We use state-of-the-art technology to make your photos look stunning. If you don't find at least one profile photo that you like, we will refund you 100%!</h3>

                <div class="relative py-8">
                    <button @click="scrollLeft(true)"
                        class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 text-white p-2 w-10 h-10 hover:bg-opacity-90 rounded-full shadow-lg z-10">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>

                    </button>
                    <div ref="scrollContainer" class="scroll-container space-x-4">
                        <figure v-for="i in 24" :key="i"
                            class="h-[740px] min-w-full sm:min-w-[405px] relative overflow-hidden rounded-3xl shadow-lg gallery-item">
                            <div class="absolute inset-0 bg-cover bg-center"
                                :style="{ backgroundImage: `url('/img/gallery/${i}.jpg')` }"></div>
                            <figcaption
                                class="absolute bottom-2 left-2 bg-gray-500 bg-opacity-45 text-gray-300 text-xs px-2 py-1 rounded-xl uppercase">
                                Created by AI
                            </figcaption>
                        </figure>
                    </div>
                    <button @click="scrollRight(true)"
                        class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 text-white p-2 w-10 h-10 hover:bg-opacity-90 rounded-full shadow-lg z-10">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>

                    </button>
                </div>
            </div>
        </section>

        


        <!-- Styles Section -->

        <section class="py-20 bg-gray-50 text-center">
            <div class="container mx-auto px-6">
                <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">

                    <!-- First Row -->
                    <div class="col-span-2 flex flex-col gap-4">
                        <div class="w-full h-full rounded-xl p-8 bg-gradient-to-tl from-white to-gray-100 shadow-lg">
                            <h1 class="text-5xl font-semibold mb-4 py-10">
                                <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Skip
                                    the studio</span>, capture the styles
                            </h1>
                            <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em]">Get stunning headshots with professional attire and beautiful backgrounds, all from the comfort of your home. Our AI ensures your photos look indistinguishable from reality!</h3>
                        </div>
                    </div>
                    <div @click="styleType = 'woman'; showFullRow = true; scrollToAnchor('stylelist');"
                        class="min-h-[200px] flex w-full overflow-hidden rounded-xl bg-gray-300 bg-cover bg-center border cursor-pointer transition-transform duration-300 hover:scale-105 active:border-blue-900 active:scale-95"
                        style="background-image:url(/img/gallery/11.jpg)">
                        <p class="text-white text-lg font-bold mt-auto w-full p-2 bg-black bg-opacity-70">
                            See styles for women
                        </p>
                    </div>
                    <div @click="styleType = 'man'; showFullRow = true; scrollToAnchor('stylelist');"
                        class="min-h-[200px] flex w-full overflow-hidden rounded-xl bg-gray-300 bg-cover bg-center border cursor-pointer transition-transform duration-300 hover:scale-105 active:border-blue-900 active:scale-95"
                        style="background-image:url(/img/gallery/14.jpg)">
                        <p class="text-white text-lg font-bold mt-auto w-full p-2 bg-black bg-opacity-70">
                            See styles for men
                        </p>
                    </div>

                    <!-- Second Row -->
                    <div id="stylelist"
                        :class="showFullRow ? 'col-span-2 lg:col-span-4 grid-cols-1' : 'col-span-2 lg:col-span-4 grid-cols-4'"
                        class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full relative pt-16">

                        <div v-if="styleType === 'woman'"
                            :class="showFullRow ? 'col-span-2 lg:col-span-4 grid-cols-1' : 'col-span-2 lg:col-span-4 grid-cols-4'">
                        </div>

                        <div v-if="!showFullRow"
                            class="absolute inset-0 bg-gradient-to-t from-white to-transparent h-1/10">
                        </div>
                    </div>

                    <div :class="showFullRow ? 'col-span-2 lg:col-span-4 grid-cols-1' : 'col-span-2 lg:col-span-4 grid-cols-4'"
                        class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full relative">
                        <div v-for="(clothing, index) in (styleType === 'woman' ? clothing_woman : clothing_man).slice(0, showFullRow ? 12 : 4)"
                            :key="clothing.id"
                            class="relative overflow-hidden rounded-xl bg-cover bg-center border lg:min-h-[400px] cursor-zoom-in"
                            @click="showFullscreen(`/img/combos/${styleType ? styleType : (index % 2 === 0 ? 'woman' : 'man')}${(index % 10) + 1}-${clothing.id}@1x.jpg`)">
                            <img :src="`/img/combos/${styleType ? styleType : (index % 2 === 0 ? 'woman' : 'man')}${(index % 10) + 1}-${clothing.id}@1x.jpg`" class="w-full h-auto rounded-xl shadow-md">
                            <div class="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-75 text-white text-center py-1 text-[10px]">
                                {{ clothing.name }}
                            </div>
                        </div>
                        <div v-if="!showFullRow"
                            class="absolute inset-0 bg-gradient-to-t from-white to-transparent h-1/10">
                        </div>
                    </div>
                    <div v-if="fullscreenImage"
                        class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 cursor-zoom-out"
                        @click="closeFullscreen">
                        <img :src="fullscreenImage" class="max-h-full max-w-full">
                    </div>

                </div>
                <h3 v-if="showFullRow" class="text-gray-500 p-4">This is just a sample of available styles - there are over 100 clothing and backdrop combinations to choose from!
                </h3>
                <h3 v-if="!showFullRow" @click="showFullRow = true;"
                    class="text-sm text-gray-500 cursor-pointer mt-2 flex justify-center hover:text-blue-800">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                    </svg>
                </h3>
            </div>
        </section>


        <!-- Pricing Section -->
        <section class="py-20 bg-white text-center">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4 pt-20">
                    <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Pro
                        quality</span>, minus the pro price
                </h1>
                <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em] pb-14">Get high-end headshots without
                    the
                    high-end cost, risk-free. Perfect for your budget, and your personal brand.</h3>
                <div class="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-stretch">
                    <div class="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                        <h2 class="text-2xl font-semibold mb-4">{{ packages[0].name }}</h2>
                        <p class="text-4xl font-bold mb-4">${{ packages[0].price }}</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center flex-shrink-0">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>


                                {{ packages[0].photo_count }} headshots
                            </li>
                            <li class="flex items-center flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ packages[0].style_count }} styles (background & clothes combinations)
                            </li>
                            <li class="flex items-center flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                4 hours
                            </li>
                        </ul>
                        <ShinyButton :href="route('login')"
                            class="py-2 px-4 text-lg text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-600">
                            Get Started
                        </ShinyButton>
                    </div>
                    <div
                        class="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 border-4 border-blue-500">

                        <h2 class="text-2xl font-semibold mb-4">{{ packages[1].name }}</h2>
                        <p class="text-4xl font-bold mb-4">${{ packages[1].price }}</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ packages[1].photo_count }} headshots
                            </li>
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ packages[1].style_count }} styles (background & clothes combinations)
                            </li>
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                3 hours
                            </li>
                            <div
                                class="absolute top-0 right-0 transform rotate-20 bg-blue-500 text-white text-xs font-bold px-2 py-1 shadow-lg">
                                Most popular
                            </div>
                        </ul>
                        <ShinyButton :href="route('login')"
                            class="py-2 px-4 text-lg text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-600">
                            Get Started
                        </ShinyButton>
                    </div>
                    <div class="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                        <h2 class="text-2xl font-semibold mb-4">{{ packages[2].name }}</h2>
                        <p class="text-4xl font-bold mb-4">${{ packages[2].price }}</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ packages[2].photo_count }} headshots
                            </li>
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ packages[2].style_count }} styles (background & clothes combinations)
                            </li>
                            <li class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 text-blue-500 mr-2 size-6 flex-shrink-0">
                                    <path fill-rule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                                2.5 hours
                            </li>
                        </ul>
                        <ShinyButton :href="route('login')"
                            class="py-2 px-4 text-lg text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-600">
                            Get Started
                        </ShinyButton>
                    </div>

                </div>
            </div>
        </section>

    <!-- Comparison section -->
 <section class="py-20 bg-gray-50 text-center">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4 pt-20">
                    <span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Better results</span>  without stressful posing
                </h1>
                <h3 class="text-xl font-semibold text-gray-500 tracking-[.011em] pb-14">We use top-tier AI models to create unique, high-quality, and realistic images, by building a high-resolution 3D-representation of your face.</h3>
                    
                <div class="overflow-x-auto">
                    <Comparison />


                </div>
            </div>
        </section>

        <!-- Privacy Section -->
        <section class="py-20 text-center bg-gradient-to-r from-blue-50 to-blue-100 ">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4 py-20">
                    <span class="bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text">Privacy
                    </span> by design
                </h1>

                <div class="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-stretch">
                    <div class="bg-gray-50 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                        <div class="flex justify-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </div>
                        <h2 class="text-2xl font-semibold mb-4 text-center">Secure Data</h2>
                        <p class="text-xl text-center">Your data is private, stored securely and you can delete it at any time.</p>
                    </div>
                    <div class="bg-gray-50 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                        <div class="flex justify-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </div>
                        <h2 class="text-2xl font-semibold mb-4 text-center">Full Control</h2>
                        <p class="text-xl text-center">Your photos are solely used to create your headshots and discarded afterwards.</p>
                    </div>
                    <div class="bg-gray-50 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105">
                        <div class="flex justify-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </div>
                        <h2 class="text-2xl font-semibold mb-4 text-center">Strictly isolated</h2>
                        <p class="text-xl text-center">Your AI model is isolated from others, under your control.</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Frequently Asked Questions Section -->
        <section class="py-20 bg-gray-50 text-center">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4 py-20">
                    Got<span class="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">
                        questions?</span>
                </h1>
                <div class="mt-10 text-left">
                    <div v-for="(faq, index) in faqs" :key="index" class="mb-4">
                        <button @click="toggleFaq(index)"
                            class="w-full flex justify-between items-center text-left text-xl font-semibold mb-2 p-4 rounded-lg focus:outline-none border border-gray-300">
                            {{ faq.question }}
                            <svg :class="{ 'transform rotate-45': activeFaq === index }"
                                class="w-6 h-6 transition-transform duration-300" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M12 4v16m8-8H4"></path>
                            </svg>
                        </button>
                        <div v-if="activeFaq === index"
                            class="text-base text-gray-700 p-4 border border-gray-200 rounded-lg ">
                            {{ faq.answer }}
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- CTA Section -->
        <section class="p-16 bg-gradient-to-r from-blue-900 to-blue-700 text-white text-center">
            <div class="container mx-auto px-6">
                <h1 class="text-5xl font-semibold mb-4">Stay in. Look your best.</h1>
                <h2 class="text-2xl font-semibold text-gray-200 tracking-[.011em] pb-5">No appointments, no studio — pro
                    headshots from the comfort of your own space. Satisfaction guaranteed or money back.
                </h2>
                <ShinyButton :href="route('login')"
                    class="py-6 px-10 text-lg text-white-900 border-none rounded-lg shadow-lg hover:bg-gray-200">
                    Try risk-free
                </ShinyButton>
            </div>
        </section>

        <!-- Footer -->
        <Footer />

    </div>





</template>
